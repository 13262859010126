/*
 * @Descripttion:
 * @version:
 * @Author: 贾峰
 * @Date: 2019-11-05 11:40:54
 * @LastEditors: 顾俊杰
 * @LastEditTime: 2020-09-28 17:23:03
 */
import axios from '@/lib/api.request'

/*
 * @Author: 贾峰
 * @Moudle: 增员管理
 */
/* ------------------------- 增员管理------------------- */
// 获取二维码
export const getQRcode = data =>
  axios.post('/hx/abtComEmpnoApply/getQrcode', data)

// 下拉框接口
export const getSelectList = data => {
  return axios.request({
    url: 'saas/select/selectMJ',
    method: 'post',
    data
  })
}
// 银行下拉框
export const selectBank = params =>
  axios.post('/saas/select/selectBaseBankcode', {
    params
  })

// 增员
export const addmember = data => axios.post('/hx/abtComEmpnoApply/add', data)

// 详情showEmpInfo
export const showEmpInfo = params =>
  axios.get('/hx/abtComEmpnoApply/showEmpInfo', {
    params
  })

// 获取最小职级
export const getMinZJ = params =>
  axios.get('/hx/abtComEmpnoApply/getMinZJ', {
    params
  })

// getopenid获取openid
export const getopenid = params =>
  axios.get('/hx/abtComEmpnoApply/getopenid', {
    params
  })

// 入司设置（是否显示附加信息）
export const getInComSetting = params =>
  axios.get('/hx/abtComSystemSetting/getDivisionAllocationConf', {
    params
  })

// 机构下拉
export const organTree = () => axios.get('/saas/select/selectTjcode')

// 身份证和银行卡识别
// 上传文件
export const uploadfiles = data =>
  axios.post('/saas/upload/uploadImg', data, {
    'Content-Type': 'multipart/form-data'
  })

// 身份证校验saas/ocr/idcardocr
export const ididentify = data =>
  axios.post('/saas/ocr/idcardocr', data, {
    'Content-Type': 'multipart/form-data'
  })

// 银行卡识别/saas/ocr/bankCardOcr
export const bankidentify = data =>
  axios.post('/saas/ocr/bankCardOcr', data, {
    'Content-Type': 'multipart/form-data'
  })

/// 工号调出姓名hx/abtComEmpno/show
export const empnoforinfo = data => axios.post('/hx/abtComEmpno/show', data)

// 检测工号
export const checkempnp = data =>
  axios.post('/hx/abtComEmpnoApply/checkempno', data)

// 检测证件号
export const checkcardno = data =>
  axios.post('/hx/abtComEmpnoApply/checkcardno', data)
// 职级下拉
export const selectZJ = params =>
  axios.get('/saas/select/selectZJ', {
    params
  })
// 根据推荐人查询机构，上级主管，育成人
export const getRelationInfo = params =>
  axios.get('/hx/abtComEmpnoApply/getRelationInfo', {
    params
  })
// 银行卡下拉
export const selectBaseBankcode = params =>
  axios.get('/saas/select/selectBaseBankcode', {
    params
  })
/*
 * @Author: 黄孝娟
 * @Moudle: 我的团队
 */
/* ------------------------- 我的团队------------------- */
// GET /hx/abtComEmpno/selectRelation/{empno}/{type}
// 查询关系树列表 GET /hx/abtComEmpno/selectRelation/{empno}/{type}
export const selectRelation = params =>
  axios.get('/hx/abtComEmpno/selectRelation', {
    params
  })
/*
 * @Author: 黄孝娟
 * @Moudle: 主管雷达
 */
/* ------------------------- 主管雷达------------------- */
// 主管雷达列表 GET /hx/abtComEmpno/getTDRadar/{empno}
export const getTDRadar = params =>
  axios.get('/hx/abtComEmpno/getTDRadar', {
    params
  })
// 主管雷达-统计day新增获客数列表 GET /hx/abtComEmpno/getTDRecordKH/{empno}/{KHday}
export const getTDRecordKH = params =>
  axios.get('/hx/abtComEmpno/getTDRecordKH', {
    params
  })
// 主管雷达-统计day阅读数列表 GET /hx/abtComEmpno/getTDRecordRD/{empno}/{Readday}
export const getTDRecordRD = params =>
  axios.get('/hx/abtComEmpno/getTDRecordRD', {
    params
  })

/*
 * @Author: 牛黎明
 * @Moudle: 团队活动量
 */
/* ------------------------- 团队活动量------------------- */
export const getTDMonCount = params =>
  axios.get('/hx/abtComEmpno/getTDMonCount', {
    params
  })

/*
 * @Author: 赵琪
 * @Moudle: 产品展示
 */
/* ------------------------- 产品展示------------------- */
// 保险公司下拉
export const choseSupplyList = params =>
  axios.get('/hx/abtComHxCpList/choseSupplyList', {
    params
  })
  // 产品列表筛选专用的保险公司下拉
export const productList = params =>
  axios.get('/saas/select/selectCPSupplieList', {
    params
  })
// 寿险保险公司下拉
export const sxchoseList = params =>
  axios.get('/saas/select/selectCPSupplieList', {
    params
  })
// 产品展示列表
export const showList = data => axios.post('/hx/abtComHxCpList/list', data)
// 筛选接口
export const choseList = params =>
  axios.get('/hx/abtComHxClassTypedefine/choseList', {
    params
  })
// 产品详情
export const comshowaa = params =>
  axios.get('/hx/abtComHxCpList/show', {
    params
  })
// 产品资料
export const selectcpzl = params =>
  axios.get('/hx/abtComHxCpList/selectShowFile', {
    params
  })
// 生成海报
export const selecschb = params =>
  axios.get('/hx/abtComHxCpList/generateHB', {
    params
  })
// 推广费下拉列表
export const selectTGF = params =>
  axios.get('/hx/abtComHxCpList/getCPXZRate', {
    params
  })
/*
 * @Author: 刘格优
 * @Moudle: 南华邀新
 */
// 获取邀新二维码
// export const getYXQrcode = params =>
//   axios.get("/hx/abtComEmpnoApply/getYXQrcode", {
//     params
//   });
// 获取邀新奖励详情
export const getYXTotal = params =>
  axios.get('/hx/abtComEmpnoApply/getYXTotal', {
    params
  })
// 获取奖励数据明细
export const getYXInfo = params =>
  axios.get('/hx/abtComEmpnoApply/getYXInfo', {
    params
  })
// 建立推荐关系
export const establishTJRelation = params =>
  axios.get('/hx/abtComEmpnoApply/establishTJRelation', {
    params
  })
// 合成前台邀新海报
export const getYXHB = params =>
  axios.get('/hx/abtComEmpnoApply/getYXHB', {
    params
  })
/*
 * @Author: 李宗哲
 * @Moudle: 南华门店
 */
// 商店产品展示列表
export const showListsd = data =>
  axios.post('/hx/abtComHxCpList/selectEmpCPList', data)
// 商店产品保存
export const showListsdadd = data =>
  axios.post('/hx/abtComHxCpList/saveEmpCPList', data)
/*
 * @Author: 刘格优
 * @Moudle: 电子入司
 */

// 填写姓名，身份证号获取执业资质
export const checkEmpCert = data =>
  axios.post('/hx/abtComEmpnoApply/checkEmpCert', data)
// 具有职业资质的入司保存
export const insertEmp = data =>
  axios.post('/hx/abtComEmpnoApply/insertEmp', data)
// 完成学习保存接口
export const studyCompletion = params =>
  axios.get('/hx/abtComEmpnoApply/studyCompletion', {
    params
  })
// 补充影像回显
export const selectSourceInfo = params =>
  axios.get('/hx/abtComEmpnoApply/selectSourceInfo', {
    params
  })
// 补充影像保存
export const saveSourceInfo = data =>
  axios.post('/hx/abtComEmpnoApply/saveSourceInfo', data)
/*
 * @Author: 李宗哲
 * @Moudle: 我的团队
 */
// 我的团队页面列表
export const selectteam = params =>
  axios.get('/hx/abtComEmpno/getTdBdData', {
    params
  })
// 查看团队成员列表
export const Listteam = params =>
  axios.get('/hx/abtComEmpno/getTdBdInfo', {
    params
  })
// 个人明细产品列表
export const Datateammx = data =>
  axios.post('/cpk/abtComCpkOrderList/list', data)
// 恒大点击获取正式链接
export const getInsureUrl = data => 
  axios.post('/hx/abtComHxCpList/getInsureUrl',data)

/*
 * @Author: 顾俊杰
 * @Moudle: 推广费
 */
// 推广费列表
  export const promotionFeeList = params =>
  axios.get('/hx/abtComHxCpList/getCPXZRate', {
    params
  })