<!--
 * @Descripttion:
 * @version:
 * @Author: 李宗哲
 * @Date: 2020-03-19 17:12:51
 * @LastEditors: 霍铁樱
 * @LastEditTime: 2020-07-22 19:02:14
 -->
 <template>
  <div class="topimgbg">
    <img src="@/assets/nh/nhwx-jczs.jpg"
         alt=""
         class="mainpic">
    <div class="contentbox">
      <div class="contentbox-article"
           v-for="(item,index) in list"
           :key="index">
        <div class="article-ziti">
          <p class="typeface">{{item.title}}</p>
        </div>
        <div class="article-button "
             v-if="item.status=='0'"
             @click="showfile(item)">
          <p class="button unfinished">未学习</p>
        </div>
        <div class="article-button"
             v-if="item.status=='1'"
             @click="showfile(item)">
          <p class="button">已完成</p>
        </div>
      </div>
      <div class="authentication">
        <div class="authen">
          <p class="tication"
             @click="sure">去认证</p>
        </div>
      </div>
    </div>
    <md-landscape v-model="showFullScreen"
                  full-screen
                  class="landscape">
      <div class="pdf">
        <div>
          <pdf v-for="i in numPages"
               :key="i"
               :page="i"
               :src="filepath"
               style="width: 100%; height: auto;"
               @num-pages="pageCount=$event"></pdf>
        </div>
        <div class="authentication">
          <div class="authen">
            <p class="tication"
               @click="close">关闭</p>
          </div>
        </div>
      </div>

    </md-landscape>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import { Toast } from 'mand-mobile'
import { studyCompletion } from '@/api/agent/agent'
import { getStorage } from '@/lib/util'
export default {
  components: {
    pdf
  },
  data () {
    return {
      list: [],
      showFullScreen: false,
      filepath: '',
      pageCount: 0,
      numPages: 0,
      applynum: '',
      ossurl: ''
    }
  },
  created () {
    this.ossurl = getStorage('ossurl', '')
    this.applynum = this.$route.query.applynum
    this.list = [
      {
        title: '人身保险的分类',
        file: `${this.ossurl}NHWX/SYSFILES/%E3%80%901%E3%80%91%E4%BA%BA%E8%BA%AB%E4%BF%9D%E9%99%A9%E7%9A%84%E5%88%86%E7%B1%BB.pdf`,
        status: '0'
      },
      {
        title: '社保和商业保险的区别',
        file: `${this.ossurl}NHWX/SYSFILES/%E3%80%902%E3%80%91%E7%A4%BE%E4%BF%9D%E5%92%8C%E5%95%86%E4%B8%9A%E4%BF%9D%E9%99%A9%E7%9A%84%E5%8C%BA%E5%88%AB.pdf`,
        status: '0'
      },
      {
        title: '人寿保险',
        file: `${this.ossurl}NHWX/SYSFILES/%E3%80%903%E3%80%91%E4%BA%BA%E5%AF%BF%E4%BF%9D%E9%99%A9%20.pdf`,
        status: '0'
      },
      {
        title: '健康保险',
        file: `${this.ossurl}NHWX/SYSFILES/%E3%80%904%E3%80%91%E5%81%A5%E5%BA%B7%E4%BF%9D%E9%99%A9.pdf`,
        status: '0'
      },
      {
        title: '意外伤害保险',
        file: `${this.ossurl}NHWX/SYSFILES/%E3%80%905%E3%80%91%E6%84%8F%E5%A4%96%E4%BC%A4%E5%AE%B3%E4%BF%9D%E9%99%A9.pdf`,
        status: '0'
      },
      {
        title: '保险合同中的几个概念',
        file: `${this.ossurl}NHWX/SYSFILES/%E3%80%906%E3%80%91%E4%BF%9D%E9%99%A9%E5%90%88%E5%90%8C%E4%B8%AD%E7%9A%84%E5%87%A0%E4%B8%AA%E6%A6%82%E5%BF%B5.pdf`,
        status: '0'
      },
      {
        title: '人身保险投保一般流程',
        file: `${this.ossurl}NHWX/SYSFILES/%E3%80%907%E3%80%91%E4%BA%BA%E8%BA%AB%E4%BF%9D%E9%99%A9%E6%8A%95%E4%BF%9D%E4%B8%80%E8%88%AC%E6%B5%81%E7%A8%8B.pdf`,
        status: '0'
      }, {
        title: '如何进行理赔报案',
        file: `${this.ossurl}NHWX/SYSFILES/%E3%80%908%E3%80%91%E5%A6%82%E4%BD%95%E8%BF%9B%E8%A1%8C%E7%90%86%E8%B5%94%E6%8A%A5%E6%A1%88.pdf`,
        status: '0'
      },
      {
        title: '万能保险相关概念',
        file: `${this.ossurl}NHWX/SYSFILES/%E3%80%909%E3%80%91%E4%B8%87%E8%83%BD%E4%BF%9D%E9%99%A9%E7%9B%B8%E5%85%B3%E6%A6%82%E5%BF%B5.pdf`,
        status: '0'
      },
      {
        title: '分红险的相关概念',
        file: `${this.ossurl}NHWX/SYSFILES/%E3%80%9010%E3%80%91%E5%88%86%E7%BA%A2%E9%99%A9%E7%9A%84%E7%9B%B8%E5%85%B3%E6%A6%82%E5%BF%B5.pdf`,
        status: '0'
      }
    ]
  },
  methods: {
    showfile (item) {
      // this.filepath = item.file
      this.numPages = 0
      this.$nextTick(() => {
        setTimeout(() => {
          this.filepath = pdf.createLoadingTask(item.file)
          this.filepath.promise.then(pdf => {
            this.numPages = pdf.numPages
          })
          this.showFullScreen = true
        }, 500)
      })
      this.title = item.title
    },
    // pdf加载时
    // loadPdfHandler () {

    // },
    close () {
      this.list.forEach((item, index) => {
        if (item.title == this.title) {
          item.status = '1'
        }
      })
      this.showFullScreen = false
    },
    sure () {
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].status == '0') {
          Toast.info('请先完成所有的学习')
          return
        }
      }
      studyCompletion({ applynum: this.applynum }).then((res) => {
        Toast.info('学习完成，请等待入司审核')
        this.$router.push('/')
      })
    }

  }
}
</script>

<style>
.pdf {
  height: 100vh;
}
.topimgbg {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 10vh;
  background-color: rgb(255, 255, 255);
}
.mainpic {
  height: auto;
}
.contentbox {
  padding: 0.2rem 0.6rem 0;
}
.contentbox-article {
  display: flex;
  justify-content: space-between;
  height: 1.8rem;
  border-bottom: 1px solid #efefef;
}

.article-ziti {
  height: 1.8rem;
}
.article-button {
  padding: 0.5rem 0 0.45rem 0.4rem;
  line-height: 0.8rem;
}
.typeface {
  line-height: 1.8rem;
}
.button {
  background-color: #bababa;
  color: #fcfcfc;
  width: 1.8rem;
  text-align: center;
  /* border-bottom: 1px solid #bababa; */
  border-radius: 0.8rem;
}
.authentication {
  margin: 0.9rem 0;
}
.authen {
  width: 80%;
  margin: auto;
  color: rgb(255, 255, 255);
  height: 1rem;
  background-image: linear-gradient(to right, #feb846, #fb6822);
  border-radius: 2rem;
}
.unfinished {
  background-image: linear-gradient(to right, #feb846, #fb6822);
}
.tication {
  text-align: center;
  line-height: 1rem;
}
/* .landscape /deep/ .md-landscape-close {
  display: none !important;
} */
.landscape /deep/ .md-icon-clear:before,
.md-icon-fail:before {
  content: "";
}
.arrow {
  text-align: right;
  padding-right: 0.5rem;
  margin-top: 2vh;
}
.turn {
  padding: 0.15rem;
  border: 1px solid #e4dddd;
}
.grey {
  color: grey;
}
</style>
